import React from "react"

import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import SolidHero from "../../components/solid-hero/solid-hero"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import TheorieIntensivkursTermine from "../../components/theorie-intensivkurs-termine/theorie-intensivkurs-termine"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"

const KlasseBPage = ({data}) => (
  <Layout>
    <SEO title="Klasse B" description={'Mach den Klassiker unter den Führerscheinen bei deiner Fahrschule in Backnang. Hier alles Wissenswerte über die B-Lizenz erfahren und einsteigen!'}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={'Klasse B'} primary title={'Autoführerschein'} subtitle={() => <>Klasse B</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn text={"Natürlich könnten wir an dieser Stelle endlose Abhandlungen schreiben. Über maximale Freiheit, ein neues (übrigens unglaubliches) Lebensgefühl, Verantwortung, Unabhängigkeit und die Magie des Fahrens. Aber sind wir ehrlich: Du liest das hier nicht, weil du romantische Fahrlehrer*innen-Lyrik brauchst, sondern handfeste Fakten. Um beispielsweise deine Eltern – oder auch dich selbst – zu überzeugen, dass ein Führerschein hermuss. Sobald du 16 ½ Jahre alt bist, darfst du dich für die Klasse B anmelden, in diesem Fall für das begleitete Fahren mit 17. Falls du lieber gleich alleine ans Steuer willst: Auch kein Problem, sobald du 17 ½ Jahre alt bist. Das Prozedere ist in jedem Fall gleich. Du machst einen Erste-Hilfe-Kurs sowie einen Sehtest, lässt ein biometrisches Passbild von dir schießen und reichst diese Unterlagen, zusammen mit einem Antrag von uns, beim zuständigen Amt ein. Das dauert ca. sechs bis acht Wochen, mit dem Theorie-Unterricht kannst du aber in der Zwischenzeit schon starten. Dann geht’s in die praktischen Übungsstunden, zur Theorie- und schließlich zur Praxisprüfung. Wenn du die jeweils bestehst (haben vor dir schon ganz andere, keine Sorge), darfst du dich auf normale PKW mit maximaler Gesamtmasse von 3,5 Tonnen, Landwirtschaftliche Zugmaschinen mit maximal 40 km/h und Mopeds, bzw. Roller mit maximal 45 km/h freuen. Und ein (wir müssen es einfach sagen) unglaubliches Lebensgefühl!"}>
      <ClassOverviewItem title={'Ausbildung'}>
        <li>Ausbildung: Theorie und Praxis</li>
        <li>Prüfung: Theorieprüfung und praktische Prüfung</li>
        <li>Eingeschlossene Klassen: AM, L</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Stundenpensum'}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 5</li>
        <li>Autobahnfahrt: 4</li>
        <li>Dunkelfahrt: 3</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 2</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Vorraussetzungen'}>
        <li>Mindestalter: 18 Jahre, 17 Jahre beim Begleiteten Fahren (BF17)</li>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Merkmale'}>
        <li>KFZ – AUSGENOMMEN KFZ DER KLASSEN AM, A1, A2 UND A</li>
        <li>... zG max. 3.500 kg</li>
        <li>... max. 8 Personen außer dem Fahrer</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Anhängerregelung'}>
        <li>... Anhänger mit zG max. 750 kg immer erlaubt</li>
        <li>... Anhänger mit zG über 750 kg erlaubt, wenn zG der Fahrzeugkombination max. 3.500 kg</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Weitere Infos'} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <TheorieIntensivkursTermine/>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseBPage

export const query = graphql`
  query KlasseBPageQuery {
    file(relativePath: { eq: "klasse-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
